import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';

//import 'bootstrap/dist/js/bootstrap.bundle';
import './scss/app.scss';

window.$ = $;
//window.jQuery = jQuery;

$(document).ready(function(){
	console.log('document ready');

	// Nested tabs
	jQuery("ul.nav-tabs a").click(function (e) {
  		e.preventDefault();  
    	jQuery(this).tab('show');
	});

	// Scrollto
  	$(".scroll-to").on('click', function(e) {

	    // Make sure this.hash has a value before overriding default behavior
	    if (this.hash !== "") {
	      // Prevent default anchor click behavior
	      e.preventDefault();

	      // Store hash
	      var hash = this.hash;

	      // Using jQuery's animate() method to add smooth page scroll
	      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
	      $('html, body').animate({
	        scrollTop: $(hash).offset().top
	      }, 800, function(){

	        // Add hash (#) to URL when done scrolling (default click behavior)
	        window.location.hash = hash;
	      });
	    } // End if
  	});

  	/// Extra content - sessie
  	$(".more_content__button").on('click', function(e){
  
  		if($(this).hasClass('toggled')){
  			$(this).removeClass('toggled');
  			$(this).html('+');
  		}else{
			$(this).addClass('toggled');
			$(this).html('-');
  		}
 
  		$(this).next(".more_content").toggle('slow', function(){
  		
  		});
	});

	// Read more - home	
	$(".read-more").on('click', function(e){
		e.preventDefault();
		$(".intro-read-more").toggle('slow', function(){});
	});

});